import CardWithHeader from 'components/common/CardWithHeader';
import { IframeQuery } from 'graphql/queries/iframe/generated/Iframe';
import React from 'react';
import { Card, Col, ListGroup, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PredictedAPIButton from './priceHubble/PredictedAPIButton';
import AddWebhookButton from './webhook/WebhookButton';
import GTMButton from './gtm/GTMButton';
import WebhookCard from './webhook/WebhookCard';
import { WebhookFormType } from './webhook/WebhookForm';
import { ProjectType } from 'graphql/types.generated';
import AddApiIntegrations from './apiIntegrations/AddApiIntegrations';
import ApiIntegrationCard from './apiIntegrations/ApiIntegrationCard';
import Select from 'components/inputs/Select';
import { Field, Form, Formik } from 'formik';
import { PredictedPriceIntegrations } from 'constants/types';

interface IframeIntegrationsParams {
  iframe: IframeQuery['iframe'];
}

const getPredictedPriceInitialValues = (iframe: IframeQuery['iframe']) => {
  if (iframe.priceHubbleConfig) return { predictedPriceIntegration: PredictedPriceIntegrations.priceHubble };
  if (iframe.sprengnetterPriceConfig) return { predictedPriceIntegration: PredictedPriceIntegrations.spregnetter };
  return { predictedPriceIntegration: null };
};

const IframeIntegrations: FC<IframeIntegrationsParams> = ({ iframe }) => {
  const { t } = useTranslation();

  const predictedPriceOptions = Object.keys(PredictedPriceIntegrations).map((name) => ({
    value: name,
    label: `iframe.integrations.${name}`,
    isDisabled: name === PredictedPriceIntegrations.spregnetter && iframe.country === 'DE',
  }));

  const predictedPriceIntegrationInitialValues = getPredictedPriceInitialValues(iframe);

  return (
    <Row>
      <Col xs={12}>
        <div className="integration-actions">
          <div className="text-end mb-3">
            <AddApiIntegrations apiIntegrations={iframe.apiIntegrations} iframeId={iframe._id} />
          </div>
          <div className="text-end mb-3">
            <AddWebhookButton iframeId={iframe._id} formType={WebhookFormType.create} />
          </div>
        </div>
      </Col>
      {iframe.webhooks.map((webhook) => {
        return (
          <Col md={6} key={webhook._id}>
            <WebhookCard {...{ webhook, iframeId: iframe._id }} />
          </Col>
        );
      })}
      {iframe.projectType === ProjectType.renovatingHouse ? (
        <Col md={6}>
          <CardWithHeader
            className="mb-3"
            title={
              <Stack direction="horizontal" gap={3}>
                {t('iframe.integrations.predictedPriceIntegration')}
              </Stack>
            }
          >
            <Formik
              enableReinitialize
              initialValues={predictedPriceIntegrationInitialValues}
              onSubmit={async () => null}
            >
              {({ values }) => (
                <Form>
                  <Row className="align-items-center">
                    <Col>
                      <Field
                        label={
                          iframe.priceHubbleConfig || iframe.sprengnetterPriceConfig
                            ? 'iframe.integrations.integratedAPI'
                            : 'iframe.integrations.predictedPriceInputLabel'
                        }
                        component={Select}
                        disabled={iframe.priceHubbleConfig || iframe.sprengnetterPriceConfig}
                        name="predictedPriceIntegration"
                        options={predictedPriceOptions}
                      />
                    </Col>
                    <Col md={2} className="text-end">
                      {values.predictedPriceIntegration ? (
                        <PredictedAPIButton type={values.predictedPriceIntegration} iframe={iframe} />
                      ) : null}
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
            {iframe.priceHubbleConfig || iframe.sprengnetterPriceConfig ? (
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Card.Text className="fw-bold mb-1">{t('iframe.integrations.username')}</Card.Text>
                  {iframe.priceHubbleConfig?.username || iframe.sprengnetterPriceConfig?.username}
                </ListGroup.Item>
              </ListGroup>
            ) : null}
            {!iframe.priceHubbleConfig && !iframe.sprengnetterPriceConfig
              ? t('iframe.integrations.predictedPriceNotConnected')
              : null}
          </CardWithHeader>
        </Col>
      ) : null}
      <Col md={6}>
        <CardWithHeader
          className="mb-3"
          title={
            <Stack direction="horizontal" gap={3}>
              {t('iframe.integrations.gtmConfiguration')}
              <GTMButton iframeId={iframe._id} isEnabled={!!iframe.gtmKey} />
            </Stack>
          }
        >
          {iframe.gtmKey ? (
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Card.Text className="fw-bold mb-1">{t('iframe.integrations.gtmKey')}</Card.Text>
                {iframe.gtmKey}
              </ListGroup.Item>
            </ListGroup>
          ) : (
            t('iframe.integrations.gtmNotConnected')
          )}
        </CardWithHeader>
      </Col>

      {iframe?.apiIntegrations?.map((integration) => (
        <ApiIntegrationCard
          key={`${integration.name}-${integration.clientId}`}
          {...integration}
          iframeId={iframe?._id}
        />
      ))}
    </Row>
  );
};

export default IframeIntegrations;
