import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Modal, useModal } from 'components/common/Modal';
import { toastErrorMessage } from 'utils/helpers';
import { confirmAlert } from 'components/common/Alert';
import { IframeQuery } from 'graphql/queries/iframe/generated/Iframe';
import { useDisablePredictedPriceIntegrationIframeMutation } from 'graphql/mutations/priceHubble/generated/DisablePredictedPriceIntegrationIframe';
import IframePredictedPriceForm from './IframePredictedPriceForm';
import { PredictedPriceIntegrations } from 'constants/types';

interface IPredictedAPIButtonParams {
  iframe: IframeQuery['iframe'];
  type: PredictedPriceIntegrations;
}

const HEADER_BY_TYPE = {
  [PredictedPriceIntegrations.priceHubble]: 'integrationWithPriceHubbleModal',
  [PredictedPriceIntegrations.spregnetter]: 'integrationWithSpregnetterModal',
};

const PredictedAPIButton: FC<IPredictedAPIButtonParams> = ({ iframe, type }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'iframe' });
  const { showModal, isVisible, hideModal } = useModal();
  const [disablePredictedPriceIntegration] = useDisablePredictedPriceIntegrationIframeMutation({
    onError: toastErrorMessage,
  });
  const onPriceHubbleIntegrateClick = useCallback(() => {
    showModal();
  }, [showModal]);

  const onDisablePriceHubbleIntegration = useCallback(async () => {
    confirmAlert({
      onConfirm: async () => {
        await disablePredictedPriceIntegration({
          variables: { iframeId: iframe._id },
        });
      },
      title: t('alert.disableIntegrationHeader'),
      message: t('alert.disableIntegrationAlert'),
    });
  }, [disablePredictedPriceIntegration, iframe._id, t]);

  const isIntegrated = iframe.priceHubbleConfig?.isEnabled || iframe.sprengnetterPriceConfig?.isEnabled;
  return (
    <>
      <Button
        className="me-1 ms-auto"
        variant={isIntegrated ? 'danger' : 'primary'}
        onClick={isIntegrated ? onDisablePriceHubbleIntegration : onPriceHubbleIntegrateClick}
      >
        {isIntegrated ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faKey} />}
      </Button>
      <Modal isVisible={isVisible} onHide={hideModal} headerCloseButton headerText={t(HEADER_BY_TYPE[type])} size="lg">
        <>
          <IframePredictedPriceForm type={type} iframeId={iframe._id} hideModal={hideModal} />
        </>
      </Modal>
    </>
  );
};

export default PredictedAPIButton;
