import React, { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FastField } from 'formik';
import { toastErrorMessage } from 'utils/helpers';
import TextInput from 'components/inputs/TextInput/TextInput';
import Button from 'components/common/Button/Button';
import { faKey, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SubmitButton from 'components/common/Button/SubmitButton';
import { IframePredictedPriceInput } from 'graphql/types.generated';
import {
  IntegrateWithPredictedPriceAPIIframeMutationVariables,
  useIntegrateWithPredictedPriceAPIIframeMutation,
} from 'graphql/mutations/priceHubble/generated/IntegrateWithPredictedPriceAPIIframe';
import { PredictedPriceIntegrations } from 'constants/types';

type IframePredictedPriceFormParams = {
  iframeId: string;
  hideModal: () => void;
  type: PredictedPriceIntegrations;
};

const validationSchema = yup.object().shape({
  username: yup.string().required('errors.requiredField'),
  password: yup.string().required('errors.requiredField'),
});

const IframePredictedPriceForm: FC<IframePredictedPriceFormParams> = ({ hideModal, iframeId, type }) => {
  const [integrateIframe] = useIntegrateWithPredictedPriceAPIIframeMutation({
    onError: toastErrorMessage,
    onCompleted: async () => {
      hideModal();
    },
  });

  const { t } = useTranslation();
  const initialValues: IframePredictedPriceInput = useMemo(
    (): IframePredictedPriceInput => ({ username: '', password: '' }),
    [],
  );
  const onSubmit = useCallback(
    async (values: IframePredictedPriceInput) => {
      const variables: IntegrateWithPredictedPriceAPIIframeMutationVariables = { iframeId };
      if (type === PredictedPriceIntegrations.priceHubble) {
        variables.priceHubbleData = {
          username: values.username,
          password: values.password,
        };
      }

      if (type === PredictedPriceIntegrations.spregnetter) {
        variables.sprengnetterData = {
          username: values.username,
          password: values.password,
        };
      }
      await integrateIframe({
        variables,
      });
    },
    [iframeId, integrateIframe, type],
  );

  return (
    <Formik
      {...{
        initialValues,
        onSubmit,
        validationSchema,
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <FastField name="username" component={TextInput} label="iframe.apiUsername" />
          <FastField name="password" component={TextInput} label="iframe.apiPassword" />

          <Stack direction="horizontal" className="justify-content-end" gap={3}>
            <Button variant="secondary" disabled={isSubmitting} onClick={hideModal}>
              {t('iframe.close')} <FontAwesomeIcon icon={faXmark} />
            </Button>
            <SubmitButton label={'iframe.integrate'} icon={faKey} />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default IframePredictedPriceForm;
