import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { IframeFragmentDoc } from '../../../fragments/iframe/generated/IframeFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConfigureIframeResultPageMutationVariables = SchemaTypes.Exact<{
  resultPageConfiguration: SchemaTypes.ResultPageConfigurationInput;
  iframeId: SchemaTypes.Scalars['ID']['input'];
}>;

export type ConfigureIframeResultPageMutation = {
  __typename?: 'Mutation';
  configureIframeResultPage: {
    __typename?: 'Iframe';
    _id: string;
    name: string;
    title: string;
    tenantId: string;
    projectType: SchemaTypes.ProjectType;
    domainWhiteListTypes: Array<string>;
    termsOfUseUrl?: string | null;
    privacyPolicyUrl?: string | null;
    headInjectionTags?: Array<string> | null;
    archived?: boolean | null;
    changedStatusAt?: any | null;
    contactEmail: string;
    gtmKey?: string | null;
    template: SchemaTypes.TemplateName;
    country?: string | null;
    integrationServices?: Array<SchemaTypes.IntegrationService> | null;
    changedStatusBy?: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    } | null;
    theme: { __typename?: 'Theme'; primaryColor: string; textColor: string; pdfButtonColor?: string | null };
    requestProjectPdfConfig: {
      __typename?: 'RequestProjectPdfEmailConfig';
      newPdfRequestNotification?: boolean | null;
      emailFrom: string;
      ccEmail?: string | null;
      subject: string;
      message: string;
      useImplementationStartPoint?: boolean | null;
      usePhoneNumber?: boolean | null;
    };
    projectPdfConfig: {
      __typename?: 'ProjectPdfConfig';
      pdfWebsiteUrl: string;
      pdfDownloadEnabledViaPublicRoute: boolean;
    };
    priceHubbleConfig?: {
      __typename?: 'PredictedPriceConfig';
      isEnabled: boolean;
      password: string;
      username: string;
    } | null;
    sprengnetterPriceConfig?: {
      __typename?: 'PredictedPriceConfig';
      isEnabled: boolean;
      password: string;
      username: string;
    } | null;
    webhooks: Array<{
      __typename?: 'Webhook';
      _id: string;
      trigger: SchemaTypes.WebhookTrigger;
      events: Array<SchemaTypes.WebhookEvent>;
      url: string;
      headers: Array<{ __typename?: 'WebhookHeader'; name: string; value: string }>;
    }>;
    resultPageConfiguration?: {
      __typename?: 'ResultPageConfiguration';
      headerSection: {
        __typename?: 'HeaderSection';
        position?: number | null;
        isEnabled?: boolean | null;
        header?: { __typename?: 'Translation'; en: string; de: string } | null;
      };
      planSection: { __typename?: 'PlanSection'; position?: number | null; isEnabled?: boolean | null };
      equivalentsSection: { __typename?: 'EquivalentsSection'; position?: number | null; isEnabled?: boolean | null };
      totalCostSection: { __typename?: 'TotalCostSection'; position?: number | null; isEnabled?: boolean | null };
      investmentsPayOfSection: {
        __typename?: 'InvestmentsPayOffSection';
        position?: number | null;
        isEnabled?: boolean | null;
      };
      firstPartnerSection: {
        __typename?: 'PartnerSection';
        position?: number | null;
        isEnabled?: boolean | null;
        header?: { __typename?: 'Translation'; en: string; de: string } | null;
        content?: { __typename?: 'Translation'; en: string; de: string } | null;
        pdfButton?: { __typename?: 'Translation'; en: string; de: string } | null;
      };
      secondPartnerSection: {
        __typename?: 'PartnerSection';
        position?: number | null;
        isEnabled?: boolean | null;
        header?: { __typename?: 'Translation'; en: string; de: string } | null;
        content?: { __typename?: 'Translation'; en: string; de: string } | null;
        pdfButton?: { __typename?: 'Translation'; en: string; de: string } | null;
      };
    } | null;
    fuelMatrix?: {
      __typename?: 'FuelMatrix';
      updatedAt?: any | null;
      configuration: {
        __typename?: 'FuelMatrixConfiguration';
        heatingOil: {
          __typename?: 'FuelMatrixConfigurationItem';
          input: number;
          unit: string;
          avgPricePerUnit: number;
          conversionFactor?: number | null;
          resultInKWhHeating?: number | null;
          avgPricePerKWh?: number | null;
        };
        naturalGas: {
          __typename?: 'FuelMatrixConfigurationItem';
          input: number;
          unit: string;
          avgPricePerUnit: number;
          conversionFactor?: number | null;
          resultInKWhHeating?: number | null;
          avgPricePerKWh?: number | null;
        };
        coal: {
          __typename?: 'FuelMatrixConfigurationItem';
          input: number;
          unit: string;
          avgPricePerUnit: number;
          conversionFactor?: number | null;
          resultInKWhHeating?: number | null;
          avgPricePerKWh?: number | null;
        };
        firewood: {
          __typename?: 'FuelMatrixConfigurationItem';
          input: number;
          unit: string;
          avgPricePerUnit: number;
          conversionFactor?: number | null;
          resultInKWhHeating?: number | null;
          avgPricePerKWh?: number | null;
        };
        woodChips: {
          __typename?: 'FuelMatrixConfigurationItem';
          input: number;
          unit: string;
          avgPricePerUnit: number;
          conversionFactor?: number | null;
          resultInKWhHeating?: number | null;
          avgPricePerKWh?: number | null;
        };
        woodPellets: {
          __typename?: 'FuelMatrixConfigurationItem';
          input: number;
          unit: string;
          avgPricePerUnit: number;
          conversionFactor?: number | null;
          resultInKWhHeating?: number | null;
          avgPricePerKWh?: number | null;
        };
        electricityUsed: {
          __typename?: 'FuelMatrixConfigurationItem';
          input: number;
          unit: string;
          avgPricePerUnit: number;
          conversionFactor?: number | null;
          resultInKWhHeating?: number | null;
          avgPricePerKWh?: number | null;
        };
        electricityProduced: {
          __typename?: 'FuelMatrixConfigurationItem';
          input: number;
          unit: string;
          avgPricePerUnit: number;
        };
        districtHeating: {
          __typename?: 'FuelMatrixConfigurationItem';
          input: number;
          unit: string;
          avgPricePerUnit: number;
          conversionFactor?: number | null;
          resultInKWhHeating?: number | null;
          avgPricePerKWh?: number | null;
        };
      };
      updatedBy?: {
        __typename?: 'BasicUserAttributes';
        _id: string;
        email: string;
        profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
      } | null;
    } | null;
    apiIntegrations?: Array<{
      __typename?: 'ApiIntegrations';
      name: SchemaTypes.ApiIntegrationNames;
      clientId: string;
    }> | null;
  };
};

export const ConfigureIframeResultPageDocument = gql`
  mutation ConfigureIframeResultPage($resultPageConfiguration: ResultPageConfigurationInput!, $iframeId: ID!) {
    configureIframeResultPage(resultPageConfiguration: $resultPageConfiguration, iframeId: $iframeId) {
      ...Iframe
    }
  }
  ${IframeFragmentDoc}
`;
export type ConfigureIframeResultPageMutationFn = Apollo.MutationFunction<
  ConfigureIframeResultPageMutation,
  ConfigureIframeResultPageMutationVariables
>;

/**
 * __useConfigureIframeResultPageMutation__
 *
 * To run a mutation, you first call `useConfigureIframeResultPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigureIframeResultPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configureIframeResultPageMutation, { data, loading, error }] = useConfigureIframeResultPageMutation({
 *   variables: {
 *      resultPageConfiguration: // value for 'resultPageConfiguration'
 *      iframeId: // value for 'iframeId'
 *   },
 * });
 */
export function useConfigureIframeResultPageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfigureIframeResultPageMutation,
    ConfigureIframeResultPageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfigureIframeResultPageMutation, ConfigureIframeResultPageMutationVariables>(
    ConfigureIframeResultPageDocument,
    options,
  );
}
export type ConfigureIframeResultPageMutationHookResult = ReturnType<typeof useConfigureIframeResultPageMutation>;
export type ConfigureIframeResultPageMutationResult = Apollo.MutationResult<ConfigureIframeResultPageMutation>;
export type ConfigureIframeResultPageMutationOptions = Apollo.BaseMutationOptions<
  ConfigureIframeResultPageMutation,
  ConfigureIframeResultPageMutationVariables
>;
